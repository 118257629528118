import * as Sentry from "@sentry/vue";
import { reactive, computed, ComputedRef } from "@vue/runtime-core";
import { defineStore } from "pinia";
import { useGlobalSettingsStore } from "@/shared/stores/GlobalSettingsStore";
import type { App } from "vue";

interface ISentryStoreData {
  sentry: any;
}

const useSentryStore = defineStore("SentryStore", () => {
  const state: ISentryStoreData = reactive({
    sentry: Sentry,
  });

  const setup = (app: App) => {
    const globalSettingsStore = useGlobalSettingsStore();

    state.sentry.init({
      app,
      dsn: globalSettingsStore.get("sentryDSN"),
      attachStacktrace: true,
      logErrors: true,
      release: globalSettingsStore.get("appVersion"),
      sampleRate: globalSettingsStore.get("sentrySampleRate"),
    });
  };
  // Initialise Sentry Object

  const sentry: ComputedRef = computed(() => state.sentry);

  return {
    setup,
    sentry,
  };
});

export { useSentryStore };
