import { createRouter, createWebHistory } from "@ionic/vue-router";
import { RouteRecordRaw } from "vue-router";

const appTitle = String(process.env.VUE_APP_GLOBAL_appTitle);

const routes: Array<RouteRecordRaw> = [
  {
    path: "",
    name: "home",
    redirect: "/login",
    meta: { title: `${appTitle}` },
  },
  {
    path: "/login",
    name: "login",
    components: { default: () => import("@/views/LoginPage.vue") }, //LoginPage,
    meta: { title: `${appTitle} - Login` },
  },
  {
    path: "/home",
    components: { default: () => import("@/views/HomePage.vue") },
    meta: { title: `${appTitle}` },
    children: [
      {
        name: "dashboard",
        path: "dashboard",
        components: {
          default: () => import("@/views/DashboardPage.vue"),
        },
        meta: { title: `${appTitle} - Dashboard` },
        children: [],
      },
      {
        name: "pbi-dashboard",
        path: "pbi-dashboard",
        components: {
          default: () => import("@/views/PBIDashboardPage.vue"),
        },
        meta: { title: `${appTitle} - Power BI Dashboard` },
        children: [],
      },
      {
        name: "dashboard-activities",
        path: "dashboard/activities/:projectId",
        components: {
          default: () => import("@/views/ActivitiesPage.vue"),
        },
        meta: { title: `${appTitle} - Activities` },
        props: true,
      },
      {
        name: "dashboard-activity",
        path: "dashboard/activities/:projectId/activity/:activityId",
        components: {
          default: () => import("@/views/ActivityPage.vue"),
        },
        meta: { title: `${appTitle} - Activity` },
        props: true,
      },
      {
        name: "projects",
        path: "projects",
        components: {
          default: () => import("@/views/ProjectsPage.vue"),
        },
        meta: { title: `${appTitle} - Projects` },
        props: true,
      },
      {
        name: "activities",
        path: "projects/activities/:projectId",
        components: {
          default: () => import("@/views/ActivitiesPage.vue"),
        },
        meta: { title: `${appTitle} - Activities` },
        props: true,
      },
      {
        name: "tracker",
        path: "projects/tracker/:projectId",
        components: {
          default: () => import("@/views/TrackerPage.vue"),
        },
        meta: { title: `${appTitle} - Tracker` },
        props: true,
      },
      {
        name: "activity",
        path: "projects/activities/:projectId/activity/:activityId",
        component: () => import("@/views/ActivityPage.vue"),
        meta: { title: `${appTitle} - Activity` },
        props: true,
      },
      {
        name: "related-activities",
        path: "projects/activities/:projectId/activity/:activityId/relatedactivities/:randomId",
        component: () => import("@/views/RelatedActivitiesPage.vue"),
        meta: { title: `${appTitle} - Related Activities` },
        props: true,
      },
      {
        name: "related-activity",
        path: "projects/activities/:projectId/activity/:activityId/relatedactivities/relatedactivity/:randomId",
        component: () => import("@/views/RelatedActivityPage.vue"),
        meta: { title: `${appTitle} - Related Activity` },
        props: true,
      },
      {
        name: "settings",
        path: "/home/settings",
        components: {
          default: () => import("@/views/SettingsPage.vue"),
        },
        meta: { title: `${appTitle} - Settings` },
        props: true,
      },
      {
        name: "users",
        path: "/home/users",
        components: {
          default: () => import("@/views/UsersPage.vue"),
        },
        meta: { title: `${appTitle} - Users` },
        props: true,
      },
      {
        name: "empty",
        path: "/home/empty",
        components: {
          default: () => import("@/views/EmptyPage.vue"),
        },
        meta: { title: `${appTitle}` },
      },
    ],
  },
];

const router = createRouter({
  history: createWebHistory(process.env.BASE_URL),
  routes,
});

router.beforeEach((to) => {
  document.title = `${to.meta["title"]}`;
});

export default router;
