import { reactive } from "@vue/runtime-core";
import { defineStore } from "pinia";
import { Preferences } from "@capacitor/preferences";
import { useSentryStore } from "@/stores/SentryStore";

import {
  get as _get,
  set as _set,
  forIn as _forIn,
  each as _each,
} from "lodash";

type IMessagesData = Record<string, unknown>;

export interface IMessagesDetails {
  messagesData: IMessagesData;
}

const useMessagesStore = defineStore("messagesStore", () => {
  const state: IMessagesDetails = reactive({
    messagesData: {},
  } as IMessagesDetails);

  const sentryStore = useSentryStore();

  // Actions
  async function readSettings(): Promise<string> {
    const source = "";
    try {
      const envVariables: IMessagesData = {};

      _each(process.env, (value, key) => {
        const keyArray = key.split("_");

        if (keyArray[2] === "MESSAGES") {
          const realValue: string | number | boolean | undefined =
            value === "true"
              ? true
              : value === "false"
              ? false
              : !isNaN(Number(value))
              ? Number(value)
              : value;
          _set(envVariables, keyArray[3], realValue);
        }
      });

      set<IMessagesData>(envVariables);
    } catch (_err) {
      const err: Error = _err as Error;
      sentryStore.sentry.captureException(err);
      console.error("ERROR: --MessagesStore.readSettings()--");
      console.error(err);
    }
    return source;
  }

  async function writeSettings(): Promise<void> {
    await Preferences.set({
      key: "messagesData",
      value: JSON.stringify(state.messagesData),
    });
  }

  async function clearSettings(): Promise<void> {
    await Preferences.clear();
  }

  async function set<T = TData>(
    key: string | T | Record<string, string>,
    value?: T
  ) {
    if (key instanceof Object || (typeof key === "object" && key !== null)) {
      _forIn(key as Record<string, TData>, (value2: TData, key2: string) => {
        __setKeyValue(key2, value2 as TData);
      });
    } else {
      __setKeyValue(key as string, value as T);
    }
  }

  function __setKeyValue<T = TData>(key: string, value: T) {
    _set(state.messagesData, key, value);
  }

  // Getters
  function get<D = TData>(key: string): D | undefined {
    const value: D | undefined = _get(state.messagesData, key) as D;
    return value;
  }

  return {
    state,
    readSettings,
    writeSettings,
    clearSettings,
    set,
    get,
  };
});

export { useMessagesStore };
