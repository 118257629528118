<template>
  <div :class="theme">
    <ion-app>
      <ion-router-outlet name="primary" />
    </ion-app>
  </div>
</template>

<script lang="ts">
import { IonApp, IonRouterOutlet } from "@ionic/vue";
import { defineComponent } from "@vue/runtime-core";
import { useUserSettingsStore } from "@/shared/stores/UserSettingsStore";

export default defineComponent({
  name: "App",
  components: {
    IonApp,
    IonRouterOutlet,
  },
  setup() {
    const userSettingsStore = useUserSettingsStore();
    const theme = userSettingsStore.get("theme");

    return { theme };
  },
});
</script>
<style lang="scss">
@import "@/theme/style.scss";
</style>
