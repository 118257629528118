import { createApp } from "vue";
import App from "./App.vue";
import router from "./router";

import { IonicVue } from "@ionic/vue";
import { Network, ConnectionStatus } from "@capacitor/network";

/* Core CSS required for Ionic components to work properly */
import "@ionic/vue/css/core.css";

/* Basic CSS for apps built with Ionic */
import "@ionic/vue/css/normalize.css";
import "@ionic/vue/css/structure.css";
import "@ionic/vue/css/typography.css";

/* Optional CSS utils that can be commented out */
import "@ionic/vue/css/padding.css";
import "@ionic/vue/css/float-elements.css";
import "@ionic/vue/css/text-alignment.css";
import "@ionic/vue/css/text-transformation.css";
import "@ionic/vue/css/flex-utils.css";
import "@ionic/vue/css/display.css";

import { createPinia } from "pinia";

import { useGlobalSettingsStore } from "@/shared/stores/GlobalSettingsStore";
import { useAuthenticationStore } from "@/shared/stores/AuthenticationStore";
import { usePersonalSettingsStore } from "@/shared/stores/PersonalSettingsStore";
import { useMessagesStore } from "@/shared/stores/MessagesStore";
import { useSentryStore } from "@/stores/SentryStore";

/* Theme variables */
import "@/shared/theme/heart.scss";

const pinia = createPinia();

const app = createApp(App).use(IonicVue).use(router).use(pinia);

const globalSettingsStore = useGlobalSettingsStore();
const authenticationStore = useAuthenticationStore();
const personalSettingsStore = usePersonalSettingsStore();
const messagesStore = useMessagesStore();

router.isReady().then(async () => {
  await globalSettingsStore.readSettings();
  await authenticationStore.readSettings();
  await personalSettingsStore.readSettings();
  await messagesStore.readSettings();

  const sentryStore = useSentryStore();
  sentryStore.setup(app);

  const status = await Network.getStatus();
  console.log("Network status set", status);
  globalSettingsStore.set("connectionStatus", status.connected);
  globalSettingsStore.set("connectionType", status.connectionType);

  Network.addListener("networkStatusChange", (status: ConnectionStatus) => {
    console.log("Network status set", status);
    globalSettingsStore.set("connectionStatus", status.connected);
    globalSettingsStore.set("connectionType", status.connectionType);
  });

  app.mount("#app");
});
