import { resolveComponent as _resolveComponent, createVNode as _createVNode, withCtx as _withCtx, normalizeClass as _normalizeClass, openBlock as _openBlock, createElementBlock as _createElementBlock } from "vue"

export function render(_ctx: any,_cache: any,$props: any,$setup: any,$data: any,$options: any) {
  const _component_ion_router_outlet = _resolveComponent("ion-router-outlet")!
  const _component_ion_app = _resolveComponent("ion-app")!

  return (_openBlock(), _createElementBlock("div", {
    class: _normalizeClass(_ctx.theme)
  }, [
    _createVNode(_component_ion_app, null, {
      default: _withCtx(() => [
        _createVNode(_component_ion_router_outlet, { name: "primary" })
      ]),
      _: 1 /* STABLE */
    })
  ], 2 /* CLASS */))
}