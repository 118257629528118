import { reactive } from "@vue/runtime-core";
import { defineStore } from "pinia";
import { Preferences } from "@capacitor/preferences";
import {
  get as _get,
  set as _set,
  forIn as _forIn,
  each as _each,
  merge as _merge,
} from "lodash";

export interface IUserSettingsStoreData {
  theme?: string;
}

const useUserSettingsStore = defineStore("UserSettingsStore", () => {
  const defaultSettings: IUserSettingsStoreData = {
    theme: "light" as string,
  } as IUserSettingsStoreData;

  // Actions
  const readSettings = async (): Promise<IUserSettingsStoreData> => {
    const newSettings: IUserSettingsStoreData = {};
    _each(defaultSettings, async (value, key) => {
      _set(
        newSettings,
        key,
        (
          await Preferences.get({
            key: key,
          })
        ).value
      );
    });

    return newSettings;
  };

  const writeSetting = async (key: string, value: string): Promise<void> => {
    await Preferences.set({
      key: key,
      value: value as string,
    });
  };

  // Setters
  const set = async (key: string | Record<string, TData>, value?: TData) => {
    if (key instanceof Object || (typeof key === "object" && key !== null)) {
      _forIn(key as Record<string, TData>, (value2: TData, key2: string) => {
        _set(state.userSettings, key2, value2);
        writeSetting(key2, value2 as string);
      });
    } else {
      _set(state.userSettings, key, value);
      writeSetting(key, value as string);
    }
  };

  // Getters
  const get = <D = TData>(key: string): D => {
    return _get<TConfig<D>, typeof key>(
      state.userSettings as unknown as TConfig<D>,
      key
    ) as D;
  };

  // State
  const state = reactive({
    userSettings: _merge(defaultSettings, readSettings()),
  });

  return { state, set, get };
});

export { useUserSettingsStore };
