import { reactive, computed, ComputedRef } from "@vue/runtime-core";
import { defineStore } from "pinia";
import { Preferences } from "@capacitor/preferences";
import { useSentryStore } from "@/stores/SentryStore";

import {
  get as _get,
  set as _set,
  forIn as _forIn,
  each as _each,
  mergeWith as _mergeWith,
} from "lodash";

type IPersonalSettingsData = Record<string, unknown>;

export interface IPersonalSettingsDetails {
  personalSettingsData: IPersonalSettingsData;
}

const usePersonalSettingsStore = defineStore("PersonalSettingsStore", () => {
  const state: IPersonalSettingsDetails = reactive({
    personalSettingsData: {},
  });

  const sentryStore = useSentryStore();

  // Actions
  async function readSettings(): Promise<string> {
    const source = "";
    try {
      const envVariables: Record<string, unknown> = {};

      _each(process.env, (value, key) => {
        if (typeof value !== "undefined") {
          const keyArray = key.split("_");

          if (keyArray[2] === "PERSONAL") {
            const realValue: string | number | boolean =
              value === "true"
                ? true
                : value === "false"
                ? false
                : !isNaN(Number(value))
                ? Number(value)
                : value;
            _set(envVariables, keyArray[3], realValue);
          }
        }
      });

      let preferencesVariables = {};
      const { value } = await Preferences.get({
        key: "personalSettingsData",
      });
      if (typeof value !== "undefined" && value !== null) {
        preferencesVariables = JSON.parse(value as string);
      }

      const mergedVariables: Record<string, string> = _mergeWith(
        {},
        envVariables,
        preferencesVariables,
        (obj, src) => {
          if (src === 0) return obj;
        }
      ) as Record<string, string>;

      set(mergedVariables);
    } catch (_err) {
      const err: Error = _err as Error;
      sentryStore.sentry.captureException(err);
      console.error("ERROR: --PersonalSettingsStore.readSettings()--");
      console.error(err);
    }
    return source;
  }

  async function writeSettings(): Promise<void> {
    await Preferences.set({
      key: "personalSettingsData",
      value: JSON.stringify(state.personalSettingsData),
    });
  }

  async function clearSettings(): Promise<void> {
    await Preferences.clear();
  }

  async function set(key: string | Record<string, TData>, value?: TData) {
    if (key instanceof Object || (typeof key === "object" && key !== null)) {
      _forIn(key as Record<string, TData>, (value2: TData, key2: string) => {
        __setKeyValue(key2, value2 as TData);
      });
    } else {
      __setKeyValue(key, value as TData);
    }
    await writeSettings();
  }

  async function setInPreferences<T = TData>(key: string, value: T) {
    await Preferences.set({
      key: key,
      value: String(value),
    });
  }

  function __setKeyValue<T = TData>(key: string, value: T) {
    _set(state.personalSettingsData, key, value);
  }

  // Getters
  function get<D = TData>(key: string): D {
    const value: D | undefined = _get(
      state.personalSettingsData,
      key
    ) as unknown as D;
    return <D>value;
  }

  async function getFromPreferences(key: string): Promise<string | undefined> {
    const value: string | undefined = (await Preferences.get({
      key: key,
    })) as unknown as string;
    set(key, value);
    return value;
  }

  const isConnected: ComputedRef<boolean> = computed(() =>
    Boolean(get<boolean>("connectionStatus"))
  );
  const debugMode: ComputedRef<boolean> = computed(() =>
    Boolean(get<boolean>("debugMode"))
  );
  const appHighlightColour: ComputedRef<string> = computed(() =>
    String(get<string>("appHighlightColour"))
  );

  return {
    state,
    readSettings,
    writeSettings,
    clearSettings,

    set,
    setInPreferences,
    get,
    getFromPreferences,

    isConnected,
    debugMode,
    appHighlightColour,
  };
});

export { usePersonalSettingsStore };
