import { computed, reactive, ComputedRef } from "@vue/runtime-core";
import { defineStore } from "pinia";
import { Preferences } from "@capacitor/preferences";
import { useSentryStore } from "@/stores/SentryStore";

import {
  get as _get,
  set as _set,
  forIn as _forIn,
  each as _each,
  mergeWith as _mergeWith,
} from "lodash";

export interface IAuthenticationDetails {
  authenticationData: IAuthenticationData;
  hasAdminLoggedin: boolean;
}

export interface ILoginParameters {
  id: number;
  token: string;
  username: string;
  firstName: string;
  lastName: string;
  companyId: number;
  userType: string;
  accessRules: Record<string, unknown>;
  projectLastVisited: number;
}

const useAuthenticationStore = defineStore("authenticationStore", () => {
  const state: IAuthenticationDetails = reactive({
    authenticationData: {},
    hasAdminLoggedin: false,
  } as IAuthenticationDetails);

  const sentryStore = useSentryStore();

  // Actions
  async function readSettings(): Promise<string> {
    const source = "";
    try {
      const envVariables: IAuthenticationData | Record<string, unknown> = {};

      _each(process.env, (value, key) => {
        if (typeof value !== "undefined") {
          const keyArray = key.split("_");

          if (keyArray[2] === "AUTHENTICATION") {
            const realValue: string | number | boolean =
              value === "true"
                ? true
                : value === "false"
                ? false
                : !isNaN(Number(value))
                ? Number(value)
                : value;
            // set(keyArray[3], realValue);
            _set(envVariables, keyArray[3], realValue);
          }
        }
      });

      let preferencesVariables = {};
      const { value } = await Preferences.get({
        key: "authenticationData",
      });
      if (typeof value !== "undefined" && value !== null) {
        preferencesVariables = JSON.parse(value as string);
      }

      const mergedVariables: Record<string, string> = _mergeWith(
        {},
        preferencesVariables,
        envVariables,
        (obj, src) => {
          if (src === 0) return obj;
        }
      ) as Record<string, string>;

      set(mergedVariables);
    } catch (_err) {
      const err: Error = _err as Error;
      sentryStore.sentry.captureException(err);
      console.error("ERROR: --AuthenticationStore.readSettings()--");
      console.error(err);
    }
    return source;
  }

  async function writeSettings(): Promise<void> {
    await Preferences.set({
      key: "authenticationData",
      value: JSON.stringify(state.authenticationData),
    });
  }

  async function clearSettings(): Promise<void> {
    await Preferences.clear();
  }

  async function set<T = TData>(
    key: string | Record<string, string>,
    value?: T
  ) {
    if (key instanceof Object || (typeof key === "object" && key !== null)) {
      _forIn(key as Record<string, TData>, (value2: TData, key2: string) => {
        __setKeyValue(key2, value2 as TData);
      });
    } else {
      __setKeyValue(key, value as T);
    }
    await writeSettings();
  }

  async function setInPreferences<T = TData>(key: string, value: T) {
    await Preferences.set({
      key: key,
      value: String(value),
    });
  }

  function __setKeyValue<T = TData>(key: string, value: T) {
    _set(state.authenticationData, key, value);
  }

  function getBase64UserPass(username: string, password: string): string {
    const userpassConcat = `${username}:${password}`;
    const base64UserPass = `${btoa(userpassConcat)}`;

    return base64UserPass;
  }

  // Getters
  function get<D = TData>(key: string): D | undefined {
    const value: D | undefined = _get(state.authenticationData, key);
    return value;
  }

  async function getFromPreferences(key: string): Promise<string | undefined> {
    const value: string | undefined = (await Preferences.get({
      key: key,
    })) as unknown as string;
    set(key, value);
    return value;
  }

  const hasAdminLoggedin = computed({
    get: () => state.hasAdminLoggedin,
    set: (value: boolean) => {
      if (typeof value === "boolean") state.hasAdminLoggedin = value;
    },
  });

  const userType: ComputedRef<string> = computed(
    (): string => state.authenticationData.userType
  );
  const companyId: ComputedRef<number> = computed(
    (): number => state.authenticationData.companyId as number
  );
  const isUserTypeGlobal = computed((): boolean => {
    return state.authenticationData.userType.indexOf("GLOBAL") >= 0;
  });
  const isUserTypeAdmin = computed((): boolean => {
    return state.authenticationData.userType.indexOf("ADMIN") >= 0;
  });
  const isUserTypeEditor = computed((): boolean => {
    return state.authenticationData.userType.indexOf("EDITOR") >= 0;
  });
  const isUserTypeViewer = computed((): boolean => {
    return state.authenticationData.userType.indexOf("VIEWER") >= 0;
  });

  const login = ({
    id,
    token,
    username,
    firstName,
    lastName,
    companyId,
    userType,
    accessRules,
    projectLastVisited,
  }: ILoginParameters): void => {
    set<number>("id", id);
    set("localAPIToken", token);
    set("username", username);
    set("firstName", firstName);
    set("lastName", lastName);
    set<number>("companyId", Number(companyId));
    set<string>("userType", userType);
    set("accessRules", JSON.stringify(accessRules));
    set<number>("projectLastVisited", projectLastVisited);

    writeSettings();
  };

  const logout = () => {
    set<number>("id", undefined);
    set<string>("localAPIToken", "");
    set<string>("username", "");
    set<string>("firstName", "");
    set<string>("lastName", "");
    set<number>("companyId", undefined);
    set<string>("userType", "");
    set<string>("accessRules", "");
    set<number>("projectLastVisited", undefined);

    writeSettings();
  };

  const isLoggedIn = async () => {
    const localAPIToken: string | undefined = get("localAPIToken");
    if (typeof localAPIToken === "string" && localAPIToken.length > 0) {
      return true;
    } else {
      return false;
    }
  };

  return {
    state,
    readSettings,
    writeSettings,
    clearSettings,
    set,
    get,
    setInPreferences,
    getFromPreferences,
    getBase64UserPass,
    hasAdminLoggedin,
    userType,
    companyId,
    isUserTypeGlobal,
    isUserTypeAdmin,
    isUserTypeEditor,
    isUserTypeViewer,
    login,
    logout,
    isLoggedIn,
  };
});

export { useAuthenticationStore };
