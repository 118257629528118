import {
  reactive,
  computed,
  ComputedRef,
  WritableComputedRef,
} from "@vue/runtime-core";
import { defineStore } from "pinia";
import { Preferences } from "@capacitor/preferences";
import { useSentryStore } from "@/stores/SentryStore";

import {
  get as _get,
  set as _set,
  forIn as _forIn,
  each as _each,
} from "lodash";

const useGlobalSettingsStore = defineStore("globalSettingsStore", () => {
  const state: IConfigCollection = reactive({
    environment: "",
    global: {},
    development: {},
    testing: {},
    staging: {},
    production: {},
    globalSettingsData: {},
  });
  state.environment = process.env.NODE_ENV || "development";
  console.log("process.env.NODE_ENV: " + process.env.NODE_ENV);

  const sentryStore = useSentryStore();

  // Actions
  async function readSettings(): Promise<string> {
    const source = "";
    try {
      const envVariables: Record<string, TData> = {};

      _each(process.env, (value, key) => {
        if (typeof value !== "undefined") {
          const keyArray = key.split("_");

          if (keyArray[2] === "GLOBAL") {
            const realValue: string | number | boolean =
              value === "true"
                ? true
                : value === "false"
                ? false
                : !isNaN(Number(value))
                ? Number(value)
                : value;
            _set(envVariables, keyArray[3], realValue);
          }
        }
      });

      set(envVariables);
    } catch (_err) {
      const err: Error = _err as Error;
      sentryStore.sentry.captureException(err);
      console.error("ERROR: --globalSettingsStore.readSettings()--");
      console.error(err);
    }
    return source;
  }

  async function writeSettings(): Promise<void> {
    await Preferences.set({
      key: "globalSettingsData",
      value: JSON.stringify(state.globalSettingsData),
    });
  }

  async function clearSettings(): Promise<void> {
    await Preferences.clear();
  }

  async function set(key: string | Record<string, TData>, value?: TData) {
    if (key instanceof Object || (typeof key === "object" && key !== null)) {
      _forIn(key as Record<string, TData>, (value2: TData, key2: string) => {
        __setKeyValue(key2, value2 as TData);
      });
    } else {
      __setKeyValue(key, value as TData);
    }
  }

  async function setInPreferences<T = TData>(key: string, value: T) {
    await Preferences.set({
      key: key,
      value: String(value),
    });
  }

  function __setKeyValue<T = TData>(key: string, value: T) {
    _set(state.globalSettingsData, key, value);
  }

  // Getters
  function get<D = TData>(key: string): D {
    const value: D | undefined = _get(
      state.globalSettingsData,
      key
    ) as unknown as D;
    return <D>value;
  }

  async function getFromPreferences(key: string): Promise<string | undefined> {
    const value: string | undefined = (await Preferences.get({
      key: key,
    })) as unknown as string;
    set(key, value);
    return value;
  }

  const isConnected: ComputedRef<boolean> = computed(() =>
    Boolean(get<boolean>("connectionStatus"))
  );
  const debugMode: ComputedRef<boolean> = computed(() =>
    Boolean(get<boolean>("debugMode"))
  );
  const areAPICallsPaused: WritableComputedRef<boolean> = computed({
    get: (): boolean => {
      return Boolean(get<boolean>("areAPICallsPaused"));
    },
    set: (value: boolean): void => {
      set("areAPICallsPaused", value);
    },
  });

  return {
    state,
    readSettings,
    writeSettings,
    clearSettings,

    set,
    setInPreferences,
    get,
    getFromPreferences,

    isConnected,
    debugMode,
    areAPICallsPaused,
  };
});

export { useGlobalSettingsStore };
